import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image' 
import ContactForm from './contact-form'
import pattern from '../assets/dot.svg' 
import HeroRatingStars from './hero-rating-stars'
import Breadcrumbs from './Breadcrumbs'

const ServiceHero = ({ slug, title, subtitle, description,heroImage,contactFormHeading,heroDescriptionImage }) => {
  const FORMOFFSETPX = 350

  const FormWrapper = styled.div.attrs({
    className: 'ml-auto w-full md:w-1/2 lg:w-1/3 bg-white rounded-lg p-8 z-10 shadow-xl border-t-4 border-blue-500',
  })`
    margin-top: 2em;
    @media (max-width: 767px) {
      margin-bottom: ${FORMOFFSETPX * -1}px;
    }
  `
  const HeroSectionOffset = styled.div`
    @media (max-width: 767px) {
      height: ${FORMOFFSETPX - 40}px;
    }
  `

  let list = description.split('-')
  let nonListSentence = list[0]
  list.splice(0, 1)
  for (let i = 0; i < list.length; i++) {
    if (list[i].charAt(0) === ' ') {
      list[i] = list[i].slice(1, list[i].length)
    }
  }

  let checklistTextClass='';
  let heroHeadingTextClass='';
  let heroDescTextClass='';
  let herosubHeadingTextClass= ''

  //if has bg image, apply following styles
  if (heroImage){
    checklistTextClass='text-white';
    heroHeadingTextClass='text-white';
    heroDescTextClass='text-white';
    herosubHeadingTextClass = 'text-white'
  } else {
  //dot pattern bg, light bg, dark text 
    checklistTextClass='text-gray-900';
    heroHeadingTextClass='text-gray-900';
    heroDescTextClass='text-gray-700';
    herosubHeadingTextClass='text-gray-600';

  }

  return (
    <>
      <div className="bg-gray-50 py-8 md:py-12 bg-cover h-auto relative" style={{'background': !heroImage ?  '' : '' }}>
       
      {heroImage ? (
          <Img fluid={heroImage.fluid} className="heroAreaImg z-0 w-full left-0 absolute top-0 h-full" alt={title}/>
        ) : null}
        
        <div className="container mx-auto px-4 md:px-6  flex flex-wrap items-center  ">
          <div className="w-full md:w-1/2 lg:w-2/3 z-10 pr-0 md:pr-10">
               <h2 className={`font-bold text-3xl md:text-4xl lg:text-5xl block  hero-title relative font-display ${heroHeadingTextClass} pb-3`} style={{lineHeight:"1.1"}}>
              {title}</h2>
              {subtitle ? <h3 className={`font-semibold  text-xl md:text-2xl lg:text-3xl      block md:leading-8 leading-7 font-display ${herosubHeadingTextClass}`}>{subtitle}</h3> : null}
            <span className={`text-lg py-4 block md:pr-20 ${heroDescTextClass}`}>{nonListSentence}</span>
            <div className={`${heroDescriptionImage ? 'flex flex-wrap justify-between' : ''}`}>
                 <div  className={`${heroDescriptionImage ? 'w-full lg:w-1/2 order-3 lg:order-1 -mr-4' : ''}`} >
           
              {list.map((item, i) => (
                <span key={i} className="py-2 flex  text-white font-semibold">
                  <svg className="h-6 w-6 p-1 bg-green-500 rounded-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>

                  <span className={`ml-2 text-lg ${checklistTextClass}`}>{item}</span></span>
              ))}
          
                <HeroRatingStars dark={heroImage ? true : false} className="lg:-mr-3"/>
                </div>  
           
              {heroDescriptionImage ? <div className="mt-4 md:mt-0 w-full lg:w-1/2 order-2 pd-12"><img src={heroDescriptionImage.file.url} alt={title} className="w-auto"/></div> : null}
             
              </div>
         
          </div>
          <FormWrapper>
            <div className="pb-2">
            <span className="text-xl md:text-2xl leading-7 font-bold text-gray-900 mb-1 block font-display">{contactFormHeading  ? contactFormHeading : 'Request a FREE Quote'}</span>
            <span className="text-base text-gray-700 block font-display">No Obligation Estimate, Expert Advice</span>
       
       </div>
         <ContactForm hideLabels={true} showPlaceholders={true}/>

          </FormWrapper>
        </div>
      </div>
      <HeroSectionOffset />
    </>
  )
}

ServiceHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heroDescriptionImage: PropTypes.object,
  description: PropTypes.string,
  contactFormHeading: PropTypes.string
}

ServiceHero.defaultProps = {
  title: PropTypes.string,
  description: PropTypes.string,
  heroDescriptionImage: null,
  contactFormHeading:'Request a FREE Quote',
}

export default ServiceHero
