import React from 'react'
import { graphql, Link} from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import TrophyBanner from '../components/trophy-banner'
import ContentSection from '../components/content-section'
import GridContentSection from '../components/grid-content-section'
import ServiceHero from '../components/service-hero'
import PredefinedComponentController from '../components/predefined-component-controller'
import { parseContent } from '../helpers/city-pages'
import { CheckCircleIcon } from '@heroicons/react/solid'

const ReactMarkdown = require('react-markdown')

export default function ServiceCityTemplate({ data, pageContext, ...props }) {
  const { city } = pageContext
  const { contentfulServiceAreaContent, allContentfulServiceArea } = data
  let citiesList = allContentfulServiceArea.edges
 
 
  const {
    serviceTitle,
    slug,
    metaTitle,
    metaDescription,
    pageContent,
    heroTitle,
    heroDescription,
    heroImage,
    contactFormHeading,
    heroSubtitle,
    trophyHeading,
    trophySubheading,
    heroDescriptionImage,
    tidbitsToUse,
  } = contentfulServiceAreaContent
  const { location, state, stateAbbreviation, tidbits, tidbits2 } = city

 // console.log('city', city)
  //console.log('DATA', contentfulServiceAreaContent)

  const Tidbits = () => {
    if (tidbitsToUse === 2) {
      return (
        <ReactMarkdown
          source={tidbits2.tidbits2}
          className="text-gray-700 prose"
        />
      )
    } else {
      return (
        <ReactMarkdown
          source={tidbits.tidbits}
          className="text-gray-700 prose"
        />
      )
    }
  }

  return (
    <Layout city={location} location={props.location}>
      <SEO
        title={parseContent(metaTitle, location)}
        description={parseContent(metaDescription, location)}
      />

      {heroTitle != null && heroDescription != null ? (
        <ServiceHero
          contactFormHeading={parseContent(contactFormHeading, location)}
          title={parseContent(heroTitle, location)}
          subtitle={parseContent(heroSubtitle, location)}
          description={parseContent(heroDescription.heroDescription, location)}
        />
      ) : null}
      <TrophyBanner subheading={parseContent(metaDescription, location)} />
      {contentfulServiceAreaContent &&
        contentfulServiceAreaContent.pageContent.map((item, i) => {
          if (item.__typename === 'ContentfulContentSection') {
            return (
              <ContentSection
                key={i}
                renderCopyAsHtml={item.renderCopyAsHtml}
                isCityPage={true}
                topHeading={parseContent(item.topHeading, location)}
                topSubheading={parseContent(item.topSubheading, location)}
                showStars={item.showStars}
                heading={parseContent(item.heading, location)}
                addImageContainer={item.addImageContainer}
                copy={parseContent(item.copy.copy, location, stateAbbreviation)}
                image={item.image}
                layout={item.layout}
                buttonText={item.buttonText}
                buttonLink={item.buttonLink}
                buttonHelperText={item.buttonHelperText}
                cssClass={item.cssClass}
              />
            )
          }
          if (item.__typename === 'ContentfulPredefinedComponents') {
            return (
              <PredefinedComponentController
                isCityPage={true}
                key={i}
                moduleId={item.moduleId}
                componentID={item.contentful_id}
              />
            )
          }
          if (item.__typename === 'ContentfulGridContentSection') {
            return (
              <GridContentSection
                isCityPage={true}
                city={location}
                citySlug={`${city.slug}-${stateAbbreviation && stateAbbreviation.toLowerCase()}`}
                checkContentForCityTag={item.checkContentForCityTag}
                key={i}
                moduleId={item.moduleId}
                componentID={item.contentful_id}
                title={item.title}
                heading={item.heading}
                subheading={item.subheading}
                copy={item.copy && item.copy.copy}
                layout={item.layout}
                mobileItemsPerRow={item.mobileItemsPerRow}
                desktopItemsPerRow={item.desktopItemsPerRow}
                items={item.items}
              />
            )
          }
          return null
        })}


      <div className="bg-white shadow-sm">
        <div className="mx-auto container px-6 sm:px-6 py-12">
        {city.zipCodes && city.zipCodes.zipCodes ? <h3 className="text-gray-600 font-semibold text-base mb-4 leading-7">Serviced Zip Codes: {city.zipCodes.zipCodes}</h3> : null}
          <h3 className="mb-4 font-semibold text-xl md:text-2xl">
            Other Service Areas
          </h3>
          <ul className="flex flex-wrap list-none m-0">
            {citiesList.map((item, i) => {
              return (
                <li key={i} className="w-1/2 md:w-1/3 lg:w-1/4 flex my-2">
                 <div className="flex-shrink-0 pr-1">
                      <CheckCircleIcon className="h-5 w-5 text-green-500" />
                    </div>{' '}
                  <Link
                       className="text-gray-700 hover:text-brand-500 font-semibold font-display"
                    title={`${parseContent(heroTitle, item.node.location)}`}
                    to={
                      '/' +
                      item.node.slug +
                      (item.node.stateAbbreviation
                        ? `-${item.node.stateAbbreviation.toLowerCase()}`
                        : '') +
                      ('-' + slug + (slug[slug.length - 1] == "/" ? "" :'/'))
                    }
                  >
                    {item.node.location}
                    {item.node.stateAbbreviation
                      ? `, ${item.node.stateAbbreviation}`
                      : null}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className="mx-auto container px-6 sm:px-6 py-12">
        
        <h3 className="mt-4 font-semibold ">
          About {location}, {stateAbbreviation}
        </h3>
        <Tidbits />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query serviceCityQuery($id: String!) {
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          id
          location
          slug
          state
          stateAbbreviation
        
        }
      }
    }
    contentfulServiceAreaContent(id: { eq: $id }) {
      id
      slug
      serviceTitle
      metaTitle
      metaDescription
      tidbitsToUse
      heroDescription {
        heroDescription
      }
      sidebar {
        id
        title
        content {
          content
        }
      }
      heroTitle
      contactFormHeading
      pageContent {
        __typename
        ... on Node {
          ... on ContentfulContentSection {
            id
            title
            topHeading
            topSubheading
            showStars
            heading
            copy {
              copy
            }
            title
            cssClass
            buttonText
            buttonLink
            addImageContainer
            buttonHelperText
            image {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid
              }
            }
            layout
            theme
            renderCopyAsHtml
          }
          ... on ContentfulPredefinedComponents {
            id
            title
            contentful_id
            moduleId
          }
        }
        ... on Node {
          ... on ContentfulGridContentSection {
            checkContentForCityTag
            id
            title
            contentful_id
            title
            subheading
            heading
            copy {
              copy
            }
            layout
            mobileItemsPerRow
            desktopItemsPerRow
            items {
              __typename
              ... on Node {
                ... on ContentfulGridItem {
                  id
                  title
                  copy {
                    copy
                  }
                  layout
                  svgIcon {
                    svgIcon
                  }
                }
                ... on ContentfulPricingPackage {
                  title
                  notes
                  whatsIncluded {
                    whatsIncluded
                  }
                  buttonLink
                  buttonText
                  startingAtPrice
                  strikeOutPrice
                  svgImage {
                    svgImage
                  }
                  image {
                    fluid(maxWidth: 350) {
                      srcSet
                      srcWebp
                      srcSetWebp
                      ...GatsbyContentfulFluid
                    }
                  }
                  description {
                    description
                  }
                  contentful_id
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`
